<script setup lang="ts">
import { updateToken } from '@/api/backend/_http';
import { RelationshipType } from '@/helpers/const';
import { toast } from '@/helpers/toast';
import { isLoanAppComplete } from '@/helpers/typeGuards';
import { useApplicationApi } from '@/helpers/useApplicationApi';
import { useDeclarationProcess } from '@/helpers/useDeclarationProcess';
import type { Applicant } from '@/types';
import { useForm } from 'vee-validate';
import { AppValues } from '@/helpers/const';

const { authorisedUser, loanApp, state, send, saveAndGoNext, goBack } = useDeclarationProcess();
const { handleSubmit } = useForm({
  initialValues: { isRequired: loanApp.value.userType === 'Primary' },
  validateOnMount: false,
});

const isIncomeExpenseRequest =
  loanApp.value.userType === 'Secondary' &&
  state.value.context.from === 'crm' &&
  loanApp.value.actionType === 'updateExisting';

const applicantsMarried = loanApp.value.applicants.some(
  (applicant) => applicant.relationshipToPrimary === RelationshipType.MARRIED_OR_DEFACTO,
);

const saveAndContinue = handleSubmit(() => {
  const applicants: Applicant[] = loanApp.value.applicants;
  send({
    type: 'UPDATE',
    loanApp: {
      ...loanApp.value,
      applicants,
    },
  });
  submitApplicantData();
});

const appApi = useApplicationApi();
const isLoading = appApi.isLoading;

const submitApplicantData = async () => {
  if (isLoanAppComplete(loanApp.value)) {
    const applicantIndex = loanApp.value.applicants.findIndex(
      (a) => loanApp.value.userType === 'Broker' || a.id === authorisedUser.id,
    );
    const result =
      isIncomeExpenseRequest && applicantsMarried
        ? await appApi.submitApplicants({
            applicant: loanApp.value.applicants[applicantIndex],
            loanApp: { ...loanApp.value },
          })
        : await appApi.submitApplicant({
            applicant: loanApp.value.applicants[applicantIndex],
            loanApp: { ...loanApp.value },
          });
    if (result.success) {
      toast({ type: 'success', title: 'Application submitted successfully' });
      // clear token after generating loan application?
      updateToken(null);
      saveAndGoNext();
    } else {
      toast({ type: 'danger', title: 'Error', description: result.error });
    }
  } else {
    console.error('loan incomplete!', loanApp.value);
    toast({
      type: 'danger',
      title: 'Error',
      description: `Could not submit your application. It looks like something is missing in the application. Please contact ${AppValues.COMPANY_NAME} on ${AppValues.CONTACT} for support.`,
    });
  }
};
</script>

<template>
  <div class="w-full">
    <div class="mb-4 pb-2">
      <h2 class="text-2xl font-medium">
        <BxHighlight v-if="loanApp.userType === 'Broker' || loanApp.actionType === 'updateExisting'">
          Confirmation
        </BxHighlight>
        <template v-else> How did you <BxHighlight>hear about us</BxHighlight>? </template>
      </h2>
    </div>
    <div class="my-8">Please click <strong>"Submit application"</strong> below to finalise your application.</div>
    <div class="mt-12">
      <BxButton id="submission-submit" variant="primary" :loading="isLoading" @click="saveAndContinue()"
        >Submit application</BxButton
      >
      <BxButton variant="link" class="ml-4" :disabled="isLoading" @click="goBack()">Back</BxButton>
    </div>
  </div>
</template>
