import type { ApplicantEntry } from '@/types';
import { ref } from 'yup';
import { yupAddress, yupObject, yupString } from '../../yup';
import { ApplicantType, Gender, RelationshipStatus, RelationshipType } from '@/helpers/const';
import dayjs from '@/helpers/dayjs';

export const applicantSchema = (primary?: ApplicantEntry) =>
  yupObject({
    type: yupString().oneOf(Object.values(ApplicantType)).required(),
    firstName: yupString()
    .required()
    .test('len', 'Max characters input is 128', (val) => val.length <= 128)
    .label('First name'),
    middleName: yupString()
    .test('len', 'Max characters input is 128', (val) => val !== undefined ? val.length <= 128 : true )
    .label('Middle name'),
    lastName: yupString().required()
    .test('len', 'Max characters input is 128', (val) => val.length <= 128)
    .label('Last name'),
    preferredName: yupString().required()
    .test('len', 'Max characters input is 128', (val) => val.length <= 128)
    .label('Preferred name'),
    gender: yupString().oneOf(Object.values(Gender)).required().label('Gender'),
    genderOther: yupString()
      .when('gender', {
        is: Gender.OTHER,
        then: (schema) => schema.required(),
      })
      .label('Gender identity'),
    birthdate: yupString()
      .test(
        'is-valid-birthdate',
        'Birthday cannot be invalid or be a future date',
        (value) => dayjs().format('YYYY-MM-DD') >= (value ?? ''),
      )
      .required()
      .label('Birthday'),
    address: yupAddress().required().label('Property address'),
    mobile: yupString()
      .matches(/^(\+\d{11,15}|04\d{8})$/, '${path} must be valid')
      .notOneOf([primary?.mobile], 'Cannot use the same mobile number as the primary applicant')
      .required()
      .label('Mobile number'),
    email: yupString()
      .email()
      .notOneOf([primary?.email], 'Cannot use the same email as the primary applicant')
      .required()
      .label('This'),
    confirmEmail: yupString()
      .oneOf([ref('email'), ''], 'Email must match')
      .required()
      .label('Email confirmation'),
    relationshipStatus: yupString().oneOf(Object.values(RelationshipStatus)).required().label('Relationship status'),
    relationshipToPrimary: yupString()
      .when('type', {
        is: ApplicantType.SECONDARY,
        then: (schema) => schema.required(),
      })
      .label('Relationship to primary applicant'),
    relationshipOtherInfo: yupString()
      .when('relationshipToPrimary', {
        is: RelationshipType.OTHER,
        then: (schema) => schema.required(),
      })
      .label('Relationship description'),
  });
