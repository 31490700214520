/* eslint-disable @typescript-eslint/no-explicit-any */
import { AssetType, GIFTED_FUNDS_CONTRIBUTION_TO_PURCHASE, SAVINGS_CONTRIBUTION_TO_PURCHASE } from '@/helpers/const';
import {
  isCreditCardLiability,
  isOtherAsset,
  isOtherLiability,
  isPropertyAsset,
  isSalaried,
  isSelfEmployed,
  isVehicleLiability,
} from '@/helpers/typeGuards';
import { mapToApi } from '@/mappers';
import type { Applicant, LoanApplication } from '@/types';

export const createApplication = (data: LoanApplication): any => {
  const jointApplication = !!data.applicants.find(
    (a) => a.type === 'secondary' && a.relationshipToPrimary === 'married_or_defacto',
  );

  const incomes = data.applicants.map((applicant) => ({
    applicantIndex: mapToApi.applicantIndex(applicant.id, data.applicants),
    income: data.financials[applicant.id].income,
  }));

  const liabilities = data.applicants.map((applicant) => ({
    applicantIndex: mapToApi.applicantIndex(applicant.id, data.applicants),
    liabilities: data.financials[applicant.id].liabilities,
  }));

  const assets = data.applicants.map((applicant) => ({
    applicantIndex: mapToApi.applicantIndex(applicant.id, data.applicants),
    assets: data.financials[applicant.id].assets,
  }));

  const fundsAssets = assets.flatMap((a) =>
    a.assets.filter(isOtherAsset).map((oa) => mapToApi.fundsAssets(oa, a.applicantIndex)),
  );

  // contributions to savings (loan details question)
  // If it exists, add it on as a savings asset of the primary applicant.
  if (data.loanDetails) {
    const adjustedSavingsAmount = Math.max(0, data.loanDetails.savingsAmount - (data.loanDetails.giftedAmount ?? 0));

    if (adjustedSavingsAmount > 0) {
      const asset = {
        applicantIndex: data.applicants.findIndex((a) => a.type === 'primary'),
        type: 'savings' as const,
        institution: SAVINGS_CONTRIBUTION_TO_PURCHASE,
        assetAmount: adjustedSavingsAmount,
        endDebtContribution: 0,
        towardsPurchase: adjustedSavingsAmount,
      };
      fundsAssets.push(asset);
    }

    if (data.loanDetails.giftedAmount > 0) {
      const giftedFundAsset = {
        applicantIndex: data.applicants.findIndex((a) => a.type === 'primary'),
        type: 'gifted_funds' as const,
        assetType: AssetType.GIFTED_FUND,
        institution: GIFTED_FUNDS_CONTRIBUTION_TO_PURCHASE,
        assetAmount: data.loanDetails.giftedAmount,
        endDebtContribution: 0,
        towardsPurchase: data.loanDetails.giftedAmount,
      };
      fundsAssets.push(giftedFundAsset);
    }
  }

  return {
    jointApplication,
    propertiesIntendToBuy: data.incomingProperties.length,
    propertiesIntendToSell: data.outgoingProperties.length,
    numberOfApplicants: data.applicants.length,
    cancelPriorApplications: data.cancelPriorApplications,

    structuralVersionNumber: data.structuralVersionNumber,

    // Applicants
    applicants: data.applicants.map((app) => mapToApi.applicant(app, data.financials[app.id])),

    // Properties
    incomingProperties: data.incomingProperties.map(mapToApi.incomingProperty),
    outgoingProperties: data.outgoingProperties.map(mapToApi.outgoingProperty),
    propertiesOwnership: mapToApi.propertyOwnership(data),

    // Income and Expenses
    fundsSalaried: incomes.flatMap(
      (i) => i.income?.employment.filter(isSalaried).map((s) => mapToApi.fundsSalaried(s, i.applicantIndex)) ?? [],
    ),
    fundsSelfEmployed: incomes.flatMap(
      (i) =>
        i.income?.employment.filter(isSelfEmployed).map((s) => mapToApi.fundsSelfEmployed(s, i.applicantIndex)) ?? [],
    ),
    fundsAdditionalIncome: data.applicants.flatMap((applicant) =>
      mapToApi.fundsAdditionalIncome(
        data.financials[applicant.id].income,
        mapToApi.applicantIndex(applicant.id, data.applicants),
      ),
    ),
    incomeExpectations: incomes.flatMap((i) => mapToApi.incomeExpectation(i.income, i.applicantIndex)),
    employmentDetails: incomes.flatMap((i) => mapToApi.employmentDetail(i.income, i.applicantIndex)),
    fundsExpenses: data.applicants.flatMap((applicant) =>
      mapToApi.fundsExpenses(data.financials[applicant.id], mapToApi.applicantIndex(applicant.id, data.applicants)),
    ),

    // Assets and Liabilities
    fundsAssets,
    assetsProperties: assets.flatMap((a) =>
      a.assets.filter(isPropertyAsset).map((pa) => mapToApi.assetProperty(pa, a.applicantIndex)),
    ),
    liabilitiesCards: liabilities.flatMap((l) =>
      l.liabilities.filter(isCreditCardLiability).map((cl) => mapToApi.liabilityCard(cl, l.applicantIndex)),
    ),
    liabilitiesVehicles: liabilities.flatMap((l) =>
      l.liabilities.filter(isVehicleLiability).map((vl) => mapToApi.liabilityVehicle(vl, l.applicantIndex)),
    ),
    liabilitiesOthers: liabilities.flatMap((l) =>
      l.liabilities.filter(isOtherLiability).map((ol) => mapToApi.liabilityOther(ol, l.applicantIndex)),
    ),

    existingApplications: [false],
    loanPurpose: data.loanType,

    // Additional funds required
    additionalFundsRequired: data.additionalFundsRequired,

    // Loan details
    loanDate: data.loanDetails?.loanDate,
    additionalFunds: mapToApi.additionalFunds(data.loanDetails),
    requestedLoanAmount: 0,
    summaryNotes: data.summaryNotes,

    // Analytics
    utmMetrics: data.utmMetrics,
  };
};

const mapApplicant = (data: LoanApplication, applicant: Applicant) => {
  const applicantIndex = mapToApi.applicantIndex(applicant.id, data.applicants);

  const numberOfDependants = data.financials[applicant.id]?.expenses?.numberOfDependants ?? 0;
  const income = data.financials[applicant.id].income;
  const fundsSalaried =
    income?.employment.filter(isSalaried).map((s) => mapToApi.fundsSalaried(s, applicantIndex)) ?? [];
  const fundsSelfEmployed =
    income?.employment.filter(isSelfEmployed).map((s) => mapToApi.fundsSelfEmployed(s, applicantIndex)) ?? [];
  const fundsAdditionalIncome = mapToApi.fundsAdditionalIncome(data.financials[applicant.id].income, applicantIndex);
  const incomeExpectations = mapToApi.incomeExpectation(income, applicantIndex);
  const employmentDetails = mapToApi.employmentDetail(income, applicantIndex);

  const fundsExpenses = mapToApi.fundsExpenses(data.financials[applicant.id], applicantIndex);

  const assets = data.financials[applicant.id].assets;
  const fundsAssets = assets.filter(isOtherAsset).map((oa) => mapToApi.fundsAssets(oa, applicantIndex));
  const assetsProperties = assets.filter(isPropertyAsset).map((pa) => mapToApi.assetProperty(pa, applicantIndex));
  const propertiesOwnership = mapToApi.applicantDeclarationPropertyOwnership(data, assets);
  const liabilities = data.financials[applicant.id].liabilities;
  const liabilitiesCards = liabilities
    .filter(isCreditCardLiability)
    .map((cl) => mapToApi.liabilityCard(cl, applicantIndex));
  const liabilitiesVehicles = liabilities
    .filter(isVehicleLiability)
    .map((vl) => mapToApi.liabilityVehicle(vl, applicantIndex));
  const liabilitiesOthers = liabilities
    .filter(isOtherLiability)
    .map((ol) => mapToApi.liabilityOther(ol, applicantIndex));

  return {
    fundsSalaried,
    fundsSelfEmployed,
    fundsAdditionalIncome,
    incomeExpectations,
    employmentDetails,
    fundsExpenses,
    fundsAssets,
    assetsProperties,
    propertiesOwnership,
    liabilitiesCards,
    liabilitiesVehicles,
    liabilitiesOthers,
    numberOfDependants,
    id: applicant.id,
  };
};

export const updateApplication = (data: LoanApplication, applicant?: Applicant): any => {
  const applicants = data.applicants;

  if (applicant) {
    return mapApplicant(data, applicant);
  } else {
    const requestData = [];
    for (const mappedApplicant of applicants) {
      requestData.push(mapApplicant(data, mappedApplicant));
    }
    return requestData;
  }
};
