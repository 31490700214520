<script setup lang="ts">
import api from '@/api';
import type { AdditionalFundsRequiredDto, ListAdditionalFundsPurposes } from '@/api/backend/public';
import { defaultAdditionalFundRequired, defaultIncomingProperty, defaultLoanDetails } from '@/helpers/builder';
import {
  depositTypeOptions,
  AdditionalFundsPurpose,
  LoanPurposeAdditionalFundsPurposeOptions,
  stampDutyStatusOptions,
  LoanType,
} from '@/helpers/const';
import { useBrokerLoanAppProcess } from '@/helpers/useBrokerLoanAppProcess';
import type { AdditionalFundsRequiredEntry, IncomingProperty, LoanDetails, Address } from '@/types';
import { loanPurposeSchema } from '@/validation';
import { useForm } from 'vee-validate';
import { onBeforeMount, ref } from 'vue';
import { snakeCase } from '@/helpers/format';

let listAdditionalFundsPurposes: ListAdditionalFundsPurposes = [];
const { state, loanApp, goBack, saveAndGoNext } = useBrokerLoanAppProcess();
const {
  values,
  meta,
  submitCount,
  handleSubmit: validateSubmit,
} = useForm<AdditionalFundsRequiredEntry>({
  validationSchema: loanPurposeSchema,
  initialValues: { ...loanApp.value.additionalFundsRequired, isAdditionalFundsRequired: undefined },
  validateOnMount: false,
});

const handleSubmit = validateSubmit(async (values) => {
  const additionalFundsPurposeId = listAdditionalFundsPurposes?.find(
    (item) => snakeCase(item.title) === values.reasonForFundsOption,
  )?.id;
  const isVacantOrRetirement =
    values.reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_VACANT_LAND ||
    values.reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME;
  const additionalFundsRequired: AdditionalFundsRequiredDto = Object.assign(defaultAdditionalFundRequired(), {
    fundsRequiredDate: isVacantOrRetirement ? values.settlementDate : values.fundsRequiredDate,
    fundsRequiredAmount: isVacantOrRetirement ? values.purchasePrice : values.fundsRequiredAmount,
    additionalFundsPurposeId: additionalFundsPurposeId ?? '',
    description: values.description,
  });

  const isContributingFundsTowardsPurchase =
    ((values.reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME &&
      values.isThereContractForSale !== undefined) ||
      values.reasonForFundsOption === AdditionalFundsPurpose.OTHER ||
      values.reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_VACANT_LAND) &&
    values.contributingFundsTowardsPurchase;
  const loanDetails: LoanDetails = Object.assign(defaultLoanDetails(), {
    savingsAmount: isContributingFundsTowardsPurchase ? values.fundsToContributeAmount : 0,
  });

  const emptyAddress = {
    addressLine: '',
    addressLine2: '',
    locality: '',
    state: '',
    postcode: '2000',
    countryCode: 'au',
  } as Address;

  const property: IncomingProperty = Object.assign(defaultIncomingProperty(), {
    settlementDate: additionalFundsRequired.fundsRequiredDate,
    address: values.propertyAddress ?? emptyAddress,
    contractForSale: values.isThereContractForSale ?? false,
    purchaseAmount: values.purchasePrice ?? 0,
    depositAmount: values.depositAmount ?? 0,
    depositType: values.depositType ?? 'cash_payment',
    stampDutyStatus: values.stampDutyStatus ?? 'already_paid',
    stampDuty: 0,
  });

  saveAndGoNext({
    additionalFundsRequired,
    incomingProperties: loanApp.value.loanType === LoanType.SINGLE_SECURITY ? [] : [property],
    loanDetails,
  });
});

const isReady = ref(false);
onBeforeMount(async () => {
  isReady.value = false;
  try {
    listAdditionalFundsPurposes = await api.backend.public.listAdditionalFundsPurposes();
  } finally {
    isReady.value = true;
  }
});
</script>

<template>
  <div>
    <div class="mb-8 pb-2">
      <h2 class="text-2xl font-medium">Tell us about the <BxHighlight>loan purpose</BxHighlight>?</h2>
    </div>
    <form style="width: 500px" @submit="handleSubmit">
      <div>
        <BxField v-slot="{ field }" name="reasonForFundsOption">
          <p v-if="!values.reasonForFundsOption" class="mb-3">Select all that apply.</p>
          <BxSelect
            v-bind="field"
            :label="values.reasonForFundsOption ? 'Loan purpose' : 'Purpose of the funds'"
            label-type="floating"
            :list-items="LoanPurposeAdditionalFundsPurposeOptions"
            class="form-control"
          />
        </BxField>
        <BxField
          v-if="
            values.reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME ||
            values.reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_VACANT_LAND
          "
          v-slot="{ field }"
          name="propertyAddress"
        >
          <BxAddressInput v-bind="field" label="Property Address" label-type="floating" class="form-control" />
        </BxField>
        <BxField
          v-if="
            values.reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME ||
            values.reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_VACANT_LAND
          "
          v-slot="{ field }"
          name="isThereContractForSale"
        >
          <div class="mb-4 mt-4">
            <BxRadio
              v-bind="field"
              label="Is there a contract for sale?"
              label-type="default"
              class="form-control"
              boolean
            />
          </div>
        </BxField>
        <template
          v-if="
            (values.reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_VACANT_LAND ||
              values.reasonForFundsOption ===
                AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME) &&
            values.isThereContractForSale !== undefined
          "
        >
          <BxField v-slot="{ field }" name="settlementDate">
            <BxDatePicker
              v-bind="field"
              :label="values.isThereContractForSale ? 'Settlement date' : 'Estimated settlement date'"
              label-type="floating"
              placeholder="DD/MM/YYYY"
              class="form-control"
              :min-date="new Date()"
            />
          </BxField>
          <BxField v-slot="{ field }" name="purchasePrice">
            <BxNumberInput
              v-bind="field"
              :label="values.isThereContractForSale ? 'Purchase price' : 'Estimated purchase price'"
              label-type="floating"
              leading-symbol="$"
              class="form-control"
            />
          </BxField>
          <BxField v-slot="{ field }" name="depositAmount">
            <BxNumberInput
              v-bind="field"
              :label="values.isThereContractForSale ? 'Deposit amount' : 'Estimated deposit amount'"
              label-type="floating"
              leading-symbol="$"
              class="form-control"
            />
          </BxField>
          <BxField v-slot="{ field }" name="depositType">
            <BxRadio
              v-bind="field"
              :options="depositTypeOptions"
              :label="values.isThereContractForSale ? 'How was your deposit paid?' : 'How is the deposit being paid?'"
              label-type="default"
              class="form-control"
            />
          </BxField>
          <BxField
            v-if="
              values.reasonForFundsOption !== AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME
            "
            v-slot="{ field }"
            name="stampDutyStatus"
          >
            <BxRadio
              v-bind="field"
              :options="stampDutyStatusOptions"
              label="How will stamp duty be paid?"
              label-type="default"
              class="form-control incoming-margin-top"
              block
            />
          </BxField>
        </template>
        <template
          v-if="
            values.reasonForFundsOption !== AdditionalFundsPurpose.PURCHASE_VACANT_LAND &&
            values.reasonForFundsOption !== AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME
          "
        >
          <BxField v-if="values.reasonForFundsOption" v-slot="{ field }" name="fundsRequiredAmount">
            <BxNumberInput
              v-bind="field"
              label="Funds required"
              label-type="floating"
              leading-symbol="$"
              class="form-control"
            />
          </BxField>
          <BxField v-if="values.reasonForFundsOption" v-slot="{ field }" name="fundsRequiredDate">
            <BxDatePicker
              v-bind="field"
              label="Funds required by"
              label-type="floating"
              placeholder="DD/MM/YYYY"
              class="form-control"
              :min-date="new Date()"
            />
          </BxField>
        </template>
        <BxField
          v-if="
            values.reasonForFundsOption === AdditionalFundsPurpose.COSMETIC_IMPROVEMENTS_BEFORE_SELLING ||
            values.reasonForFundsOption === AdditionalFundsPurpose.OTHER
          "
          v-slot="{ field }"
          name="description"
        >
          <div class="mt-6">
            <p v-if="values.reasonForFundsOption === AdditionalFundsPurpose.COSMETIC_IMPROVEMENTS_BEFORE_SELLING">
              Explain the cosmetic improvement being done
            </p>
            <p v-if="values.reasonForFundsOption === AdditionalFundsPurpose.OTHER">
              Provide the details of the additional borrowing purposes
            </p>
            <BxTextarea class="mt-2" :rows="5" v-bind="field" />
          </div>
        </BxField>
        <BxField
          v-if="
            ((values.reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME ||
              values.reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_VACANT_LAND) &&
              values.isThereContractForSale !== undefined) ||
            values.reasonForFundsOption === AdditionalFundsPurpose.OTHER
          "
          v-slot="{ field }"
          name="contributingFundsTowardsPurchase"
        >
          <div class="mt-4">
            <BxRadio
              v-bind="field"
              label="Is your client contributing funds towards the purchase?"
              label-type="default"
              class="form-control"
              boolean
            />
          </div>
        </BxField>
        <BxField v-if="values.contributingFundsTowardsPurchase" v-slot="{ field }" name="fundsToContributeAmount">
          <div class="mt-4">
            <BxNumberInput
              v-bind="field"
              label="Funds to contribute amount"
              label-type="floating"
              leading-symbol="$"
              class="form-control"
            />
          </div>
        </BxField>
      </div>
      <BxErrorPanel v-show="!meta.valid && submitCount > 0" class="mt-6">
        Please review the error messages above.
      </BxErrorPanel>
      <div class="mt-6">
        <BxButton variant="primary" :loading="!isReady" type="submit">Save and continue</BxButton>
        <BxButton v-if="state.nextEvents.includes('BACK')" variant="link" class="ml-4" @click="goBack()">Back</BxButton>
      </div>
    </form>
  </div>
</template>
