<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useForm } from 'vee-validate';
import { assetSchema } from '@/validation';
import type { ApplicantId, AssetDataEntry, PropertyOwnership } from '@/types';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { AssetType, propertyPurposeOptions } from '@/helpers/const';
import { forceScrollToTop } from '@/helpers/forceScrollToTop';
import PropertyAssetOwnershipForm from '../../modules/PropertyOwnership/PropertyAssetOwnershipForm.vue';
import { useLoanAppProcess } from '@/helpers/useLoanAppProcess';

const props = withDefaults(
  defineProps<{
    applicantId: string;
    assetNumber: number;
    modelValue: AssetDataEntry;
  }>(),
  {},
);

const { loanApp } = useLoanAppProcess();

const applicantIndex = computed<number>(() => {
  return loanApp.value.applicants.findIndex((item) => item.id === props.applicantId);
});

const emit = defineEmits<{
  (e: 'update:modelValue', v: AssetDataEntry): void;
  (e: 'save'): void;
  (e: 'remove', assetNumber: number): void;
}>();

const assetOptions = {
  [AssetType.GIFTED_FUND]: { label: 'Gifted fund', description: 'Gift' },
  [AssetType.PROPERTY]: { label: 'Property', description: 'Property' },
  [AssetType.SAVINGS]: { label: 'Savings account', description: 'Savings' },
  [AssetType.SHARES]: { label: 'Share portfolio', description: 'Shares' },
  [AssetType.SUPERANNUATION]: { label: 'Superannuation fund', description: 'Superannuation' },
};

const purposeOptions = {
  investment_property: {
    label: propertyPurposeOptions.investment_property,
    description: 'Investment property',
  },
  owner_occupied: {
    label: propertyPurposeOptions.owner_occupied,
    description: 'Owner occupied',
  },
};

const newItem = ref(!props.modelValue.type);
const showEntry = ref(!props.modelValue.type);

const { values, meta, submitCount, handleSubmit, resetForm } = useForm<AssetDataEntry>({
  validationSchema: assetSchema,
  validateOnMount: false,
});

const app = loanApp.value.applicants.reduce((obj, item) => {
  obj[item.id] = Math.floor(100 / loanApp.value.applicants.length);
  return obj;
}, {} as Record<ApplicantId, number>);

// Resets property ownership to 50:50 if the number of applicants have changed
const applicantsChanged =
  props.modelValue.propertyOwnership?.share &&
  Object.keys(props.modelValue.propertyOwnership.share).length !== loanApp.value.applicants.length;

const po: PropertyOwnership =
  props.modelValue.propertyOwnership && !applicantsChanged
    ? {
        ...props.modelValue.propertyOwnership,
      }
    : { id: `asset-${props.assetNumber}`, type: 'asset', share: { ...app } }; // provide default values

const propertyOwnership = ref<PropertyOwnership>(po);

const cardTitle = computed(() => {
  let title;
  if (values.type) {
    const description = newItem.value
      ? ''
      : values.type === AssetType.PROPERTY
      ? values.address?.addressLine
      : values.institution;

    title = newItem.value
      ? assetOptions[values.type].label
      : `${assetOptions[values.type].description}: ${description}`;
  } else {
    title = 'Select asset';
  }
  return title;
});

const update = handleSubmit(
  (values) => {
    showEntry.value = false;
    newItem.value = false;
    emit('update:modelValue', { ...values, propertyOwnership: propertyOwnership.value });
    forceScrollToTop(0);
    return true;
  },
  () => false,
);

const cancel = () => {
  resetForm();
  showEntry.value = false;
  forceScrollToTop(0);
};

const editItem = () => {
  showEntry.value = true;
};

watch(
  () => props.modelValue,
  (data) => {
    const values = { ...data };
    resetForm({ values });
  },
  { immediate: true, deep: true },
);

defineExpose({ update, cancel });

const showModal = ref(false);
const openModal = () => {
  showModal.value = true;
};
const removeItem = () => {
  showModal.value = false;
  emit('remove', props.modelValue.assetNumber || 0);
};
</script>

<template>
  <div>
    <BxExpander v-model="showEntry" separator>
      <template #header>
        <div class="py-5 pl-3 sm:pl-4">
          <div class="flex flex-wrap items-center gap-3 sm:flex-nowrap">
            <BxIcon class="text-bridgit-green" name="income" />
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              {{ cardTitle }}
            </h3>
            <div class="flex-grow"></div>
            <div v-if="!showEntry" class="flex-shrink-0">
              <BxButton v-if="!newItem" variant="secondary" @click="editItem()">Edit</BxButton>
              <BxButton variant="link" class="ml-4" @click="openModal()">Remove</BxButton>
            </div>
          </div>
        </div>
      </template>
      <template #body>
        <div class="p-6">
          <BxField v-slot="{ field }" name="type">
            <BxRadio
              v-bind="field"
              :id="applicantId + '-asset-' + assetNumber + '-type'"
              :options="assetOptions"
              label="Do you have any of the following assets?"
              label-type="default"
              block
              class="form-control mb-12"
            />
          </BxField>
          <div v-if="!values.type"></div>
          <template v-else-if="values.type === AssetType.PROPERTY">
            <BxField v-slot="{ field }" name="address">
              <BxAddressInput
                :id="applicantId + '-asset-' + assetNumber + '-property-address'"
                v-bind="field"
                label="Property Address"
                label-type="floating"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="propertyPurpose">
              <BxRadio
                v-bind="field"
                :id="'applicant-' + applicantIndex + '-asset-' + assetNumber + '-property-purpose'"
                :options="purposeOptions"
                label="What is the purpose of this property?"
                label-type="default"
                block
                class="form-control mb-12"
              />
            </BxField>
            <BxField v-slot="{ field }" name="propertyValue">
              <BxNumberInput
                v-bind="field"
                :id="applicantId + '-asset-' + assetNumber + '-property-value'"
                label="Property value"
                label-type="floating"
                leading-symbol="$"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="rentalIncome">
              <BxPeriodicAmount
                :id="applicantId + '-asset-' + assetNumber + '-property-rental-income'"
                v-bind="field"
                label="Rental income"
                label-type="floating"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="amountOwing">
              <BxNumberInput
                v-bind="field"
                :id="applicantId + '-asset-' + assetNumber + '-property-amount-owed'"
                label="Amount owing"
                label-type="floating"
                leading-symbol="$"
                class="form-control"
              />
            </BxField>
            <div class="asset-property-ownership form-control mt-10">
              <h4 class="text-lg">
                <div class="font-medium">Property ownership</div>
              </h4>
              <PropertyAssetOwnershipForm
                v-model="propertyOwnership.share"
                :asset-applicant-id="applicantId"
                :asset-number="assetNumber"
                class="mt-2"
              />
            </div>
          </template>
          <template v-else>
            <BxField v-slot="{ field }" name="institution">
              <BxInput
                :id="applicantId + '-asset-' + assetNumber + '-institution'"
                v-bind="field"
                label="Name of institution"
                label-type="floating"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="value">
              <BxNumberInput
                v-bind="field"
                :id="applicantId + '-asset-' + assetNumber + '-amount'"
                label="Value"
                label-type="floating"
                leading-symbol="$"
                class="form-control"
              />
            </BxField>
          </template>

          <BxErrorPanel v-show="!meta.valid && submitCount > 0" class="mt-6">
            Please review the error messages above.
          </BxErrorPanel>
          <div class="mt-10 flex-shrink-0">
            <BxButton :id="applicantId + '-asset-' + assetNumber + '-save'" variant="secondary" @click="update()"
              >Save</BxButton
            >
            <BxButton variant="link" class="ml-4" @click="newItem ? openModal() : cancel()">Cancel</BxButton>
          </div>
        </div>
      </template>
    </BxExpander>
    <BxModal
      :open="showModal"
      :icon="TrashIcon"
      title="Remove asset"
      confirm-label="Remove"
      cancel-label="Confirm"
      @close="showModal = false"
      @confirm="removeItem()"
    >
      Do you want to remove
      <span class="text-bridgit-green">{{ newItem ? 'the new asset' : cardTitle }}</span
      >?
    </BxModal>
  </div>
</template>

<style lang="scss" scoped>
.asset-property-ownership {
  padding: 1rem;
  background-color: #f5f9fc;
}
</style>
