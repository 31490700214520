import type { PropertyPurposeType } from '@/types/api';

interface PeriodDefinition {
  label: string;
  multiplier: number;
}

export const periodEnum = ['week', 'fortnight', 'month', 'quarter', 'year'] as const;

export const periodType: {
  [K in (typeof periodEnum)[number]]: PeriodDefinition;
} = {
  week: { label: 'weekly', multiplier: 52 },
  fortnight: { label: 'fortnightly', multiplier: 26 },
  month: { label: 'monthly', multiplier: 12 },
  quarter: { label: 'quarterly', multiplier: 4 },
  year: { label: 'annually', multiplier: 1 },
};

export const EquityReleasePurposeOptions = [
  { label: 'Contribution towards the purchase of a land lease, retirement or community property', eligible: true },
  { label: 'Deposit for the purchase of new property', eligible: true },
  { label: 'Contribution to the purchase of a new home', eligible: true },
  { label: 'Non structural renovations and home improvements', eligible: false },
  { label: 'Travel, entertainment or leisure', eligible: false },
  { label: 'Purchase of a car', eligible: false },
];

export const AdditionalFundsPurpose = {
  FINANCIAL_CONSOLIDATION: 'financial_consolidation',
  COSMETIC_IMPROVEMENTS_BEFORE_SELLING: 'cosmetic_improvements_before_selling',
  PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME: 'purchase_retirement_land_lease_or_community_home',
  PURCHASE_VACANT_LAND: 'purchase_vacant_land',
  OTHER: 'other',
} as const;

export const AdditionalFundsPurposeOptions = {
  [AdditionalFundsPurpose.FINANCIAL_CONSOLIDATION]: 'Financial consolidation',
  [AdditionalFundsPurpose.COSMETIC_IMPROVEMENTS_BEFORE_SELLING]: 'Cosmetic improvements before selling',
  [AdditionalFundsPurpose.OTHER]: 'Other (select if there is more than 1 loan purpose)',
};

export const LoanPurposeAdditionalFundsPurposeOptions = {
  [AdditionalFundsPurpose.COSMETIC_IMPROVEMENTS_BEFORE_SELLING]: 'Cosmetic improvements before selling',
  [AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME]:
    'Purchase retirement, land lease or community home',
  [AdditionalFundsPurpose.PURCHASE_VACANT_LAND]: 'Purchase vacant land',
  [AdditionalFundsPurpose.OTHER]: 'Other (select if there is more than 1 loan purpose)',
};

export const BridgingPurposeOptions = [
  { label: 'Home renovation', eligible: true },
  { label: 'Other', eligible: true },
];

export const stateList = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

export const LoanType = {
  BRIDGING: 'buy_now',
  EQUITY_RELEASE: 'equity_release',
  SINGLE_SECURITY: 'single_security',
} as const;

// -------------------------------------------------------------------------------------------
// TODO: this entire block of code should draw property type values from `src/api/enums/PropertyType.enum.ts`
// so that the backend and swagger definitions are the single / only source of truth for what this data is
export const PropertyType = {
  RESIDENTIAL: 'residential',
  RURAL_RESIDENTIAL: 'rural_residential',
  OFF_PLAN_RESIDENTIAL: 'off_plan_residential',
  COMMERCIAL: 'commercial',
  RETIREMENT_LAND_OR_COMMUNITY_HOME: 'retirement_land_lease_or_community_home',
  VACANT_LAND: 'vacant_land',
} as const;

export const standardBridgeTypes = [
  PropertyType.RESIDENTIAL,
  PropertyType.RURAL_RESIDENTIAL,
  PropertyType.OFF_PLAN_RESIDENTIAL,
];

export const PropertyToFundsPurposeMap = {
  [PropertyType.RESIDENTIAL]: null,
  [PropertyType.RURAL_RESIDENTIAL]: null,
  [PropertyType.OFF_PLAN_RESIDENTIAL]: null,
  [PropertyType.COMMERCIAL]: null,
  [PropertyType.RETIREMENT_LAND_OR_COMMUNITY_HOME]:
    AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME,
  [PropertyType.VACANT_LAND]: AdditionalFundsPurpose.PURCHASE_VACANT_LAND,
};

export const propertyTypeOptions = {
  [PropertyType.RESIDENTIAL]: 'Residential',
  [PropertyType.RURAL_RESIDENTIAL]: 'Rural Residential',
  [PropertyType.OFF_PLAN_RESIDENTIAL]: 'Off-the-plan Residential',
  [PropertyType.COMMERCIAL]: 'Commercial',
  [PropertyType.RETIREMENT_LAND_OR_COMMUNITY_HOME]: 'Retirement, land-lease or community home',
  [PropertyType.VACANT_LAND]: 'Vacant Land',
};

export const incomingPropertyTypeOptions = {
  [PropertyType.RESIDENTIAL]: 'Residential',
  [PropertyType.RURAL_RESIDENTIAL]: 'Rural Residential',
  [PropertyType.OFF_PLAN_RESIDENTIAL]: 'Off-the-plan Residential',
  [PropertyType.RETIREMENT_LAND_OR_COMMUNITY_HOME]: 'Retirement, land-lease or community home',
  [PropertyType.VACANT_LAND]: 'Vacant Land',
};

export const BrokerPropertyType = {
  RESIDENTIAL: 'residential',
  RURAL_RESIDENTIAL: 'rural_residential',
  OFF_PLAN_RESIDENTIAL: 'off_plan_residential',
  COMMERCIAL: 'commercial',
  RETIREMENT_LAND_LEASE_COMMUNITY_HOME: 'retirement_land_lease_or_community_home',
  VACANT_LAND: 'vacant_land',
} as const;

export const brokerIncomingPropertyTypeOptions = {
  [BrokerPropertyType.RESIDENTIAL]: 'Residential',
  [BrokerPropertyType.RURAL_RESIDENTIAL]: 'Rural Residential',
  [BrokerPropertyType.OFF_PLAN_RESIDENTIAL]: 'Off the plan Residential',
  [BrokerPropertyType.RETIREMENT_LAND_LEASE_COMMUNITY_HOME]: 'Retirement, land-lease or community home',
  [BrokerPropertyType.VACANT_LAND]: 'Vacant Land',
};

export const outgoingPropertyTypeOptions = {
  [PropertyType.RESIDENTIAL]: 'Residential',
  [PropertyType.RURAL_RESIDENTIAL]: 'Rural Residential',
  [PropertyType.OFF_PLAN_RESIDENTIAL]: 'Off-the-plan Residential',
  [PropertyType.COMMERCIAL]: 'Commercial',
  [PropertyType.VACANT_LAND]: 'Vacant Land',
};
// -------------------------------------------------------------------------------------------

export const RelationshipStatus = {
  SINGLE: 'single',
  MARRIED_OR_DEFACTO: 'married_or_defacto',
} as const;

export const relationshipStatusOptions = {
  [RelationshipStatus.SINGLE]: 'Single',
  [RelationshipStatus.MARRIED_OR_DEFACTO]: 'Married/Defacto',
};

export const RelationshipType = {
  MARRIED_OR_DEFACTO: 'married_or_defacto',
  FRIEND: 'friend',
  SIBLING: 'sibling',
  OTHER: 'other',
} as const;

export const relationshipTypeOptions = {
  [RelationshipType.MARRIED_OR_DEFACTO]: 'Married/Defacto',
  [RelationshipType.FRIEND]: 'Friend',
  [RelationshipType.SIBLING]: 'Sibling',
  [RelationshipType.OTHER]: 'Other',
};

export const Gender = {
  MALE: 'male',
  FEMALE: 'female',
  PREFER_NOT_TO_SAY: 'prefer_not_to_say',
  OTHER: 'other',
} as const;

export const genderOptions = {
  [Gender.MALE]: 'Male',
  [Gender.FEMALE]: 'Female',
  [Gender.PREFER_NOT_TO_SAY]: 'Prefer not to say',
  [Gender.OTHER]: 'Prefer to self describe as (please specify)',
};

export const ApplicantType = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
} as const;

export const EmploymentType = {
  FULL_TIME: 'full_time',
  PART_TIME: 'part_time',
  CASUAL: 'casual',
  SELF_EMPLOYED: 'self_employed',
  UNEMPLOYED: 'unemployed',
  RETIRED: 'retired',
} as const;

export const employmentTypeOptions = {
  [EmploymentType.FULL_TIME]: { label: 'Full-time employment', description: 'Full-time', employed: true },
  [EmploymentType.PART_TIME]: { label: 'Part-time employment', description: 'Part-time', employed: true },
  [EmploymentType.CASUAL]: { label: 'Casual/temporary employment', description: 'Casual/temporary', employed: true },
  [EmploymentType.SELF_EMPLOYED]: {
    label: 'Self-employed/contractor',
    description: 'Self-employed/contractor',
    employed: true,
  },
  [EmploymentType.RETIRED]: { label: "No income. I'm retired", description: 'Retired', employed: false },
  [EmploymentType.UNEMPLOYED]: { label: "No income. I'm unemployed", description: 'Unemployed', employed: false },
};

export const DepositType = {
  CASH: 'cash_payment',
  BOND: 'deposit_bond',
} as const;

export const depositTypeOptions = {
  [DepositType.CASH]: 'Cash payment',
  [DepositType.BOND]: 'Deposit bond',
};

export const StampDutyStatus = {
  AT_SETTLEMENT: 'at_settlement',
  PRIOR_TO_SETTLEMENT: 'prior_to_settlement',
  ALREADY_PAID: 'already_paid',
} as const;

export const propertyPurposeOptions: Record<PropertyPurposeType, string> = {
  owner_occupied: 'A home to live in',
  investment_property: 'An investment property',
};

export const stampDutyStatusOptions = {
  [StampDutyStatus.AT_SETTLEMENT]: 'To be paid on settlement',
  [StampDutyStatus.PRIOR_TO_SETTLEMENT]: 'To be paid prior to settlement',
  [StampDutyStatus.ALREADY_PAID]: 'Already paid',
};

export const SaleStatus = {
  SOLD: 'sold',
  LISTED_FOR_SALE: 'listed_for_sale',
  NONE: 'no',
} as const;

export const saleStatusOptions = {
  [SaleStatus.SOLD]: 'Sold',
  [SaleStatus.LISTED_FOR_SALE]: 'Listed for sale',
  [SaleStatus.NONE]: 'Not yet listed',
};

export const enum LoanPeriod {
  LESS_THAN_MONTH = '1',
  IN_1_2_MONTHS = '2',
  IN_2_3_MONTHS = '3',
  IN_3_PLUS_MONTHS = '6',
  EXACT_DATE = 'Custom',
}

export const loanPeriodOptions = {
  [LoanPeriod.LESS_THAN_MONTH]: 'In less than a month',
  [LoanPeriod.IN_1_2_MONTHS]: 'In 1-2 months',
  [LoanPeriod.IN_2_3_MONTHS]: 'In 2-3 months',
  [LoanPeriod.IN_3_PLUS_MONTHS]: 'In 3+ months',
  [LoanPeriod.EXACT_DATE]: 'I know the exact date',
};

export const AssetType = {
  PROPERTY: 'property',
  SAVINGS: 'savings',
  SHARES: 'shares',
  SUPERANNUATION: 'superannuation',
  GIFTED_FUND: 'gifted_funds',
} as const;

export const LiabilityType = {
  CARD: 'card',
  VEHICLE: 'vehicle',
  OTHER: 'other',
} as const;

export const CurrentKey = 'currentKeyV5';
export const SAVINGS_CONTRIBUTION_TO_PURCHASE = 'Savings to contribute to purchase';
export const GIFTED_FUNDS_CONTRIBUTION_TO_PURCHASE = 'Gifted funds to contribute to purchase';

export const enum DeclarationType {
  AGREEMENT = 'agreement',
}

export const enum DeclarationDescription {
  ACCEPTED = 'accepted',
}

export const expenseTypeDescriptions: Record<string, Record<string, string>> = {
  clothing: {
    shortFormTitle: 'Clothing & personal care',
    tooltip: 'Clothing, footwear, personal care products, cosmetics, hair services and accessories.',
  },
  groceries: {
    shortFormTitle: 'Groceries',
    tooltip: 'Food and non-alcoholic beverages, toiletries and cleaning products.',
  },
  government_and_education: {
    shortFormTitle: 'Gov. & general education',
    tooltip: 'Public or government, primary and secondary school tuition, school and sports fees.',
  },
  childcare: {
    shortFormTitle: 'Child care & private fees',
    tooltip: 'Childcare and non-compulsory pre-school, private school fees or tuition.',
  },
  general_insurance: {
    shortFormTitle: 'General insurances',
    tooltip: 'Home, contents and vehicle insurances.',
  },
  personal_insurance: {
    shortFormTitle: 'Personal insurances',
    tooltip: 'Life, personal, medical and health insurances.',
  },
  communication: {
    shortFormTitle: 'Communication costs',
    tooltip: 'Phone, mobile phone, internet bills and other subscriptions.',
  },
  house_expenses: {
    shortFormTitle: 'Household expenses',
    tooltip: 'Rates, gas and electricity bills.',
  },
  transport: {
    shortFormTitle: 'Transportation',
    tooltip: 'Public transport, taxis and vehicle expenses.',
  },
  rent: {
    shortFormTitle: 'Rent',
    tooltip: 'Rent on primary residence.',
  },
  other_property_fees: {
    shortFormTitle: 'Other property costs',
    tooltip: 'Owner-occupied and investment property body corporate fees.',
  },
  recreation: {
    shortFormTitle: 'Entertainment costs',
    tooltip: 'Recreation and entertainment, including takeaways and dining out.',
  },
  child_support: {
    shortFormTitle: 'Child support',
    tooltip: 'Child support and maintenance payments.',
  },
  others: {
    shortFormTitle: 'Other',
    tooltip: 'All other expenses not included above.',
  },
  education: {
    shortFormTitle: 'Education',
    tooltip: 'Education.',
  },
  insurance_premium: {
    shortFormTitle: 'Insurance',
    tooltip: 'Insurance.',
  },
  medical_health: {
    shortFormTitle: 'Medical',
    tooltip: 'Medical.',
  },
  home_property_cost: {
    shortFormTitle: 'Home property costs',
    tooltip: 'Home property costs.',
  },
  investment_property_cost: {
    shortFormTitle: 'Investment property costs',
    tooltip: 'Costs associated with any property owned for investment purposes',
  },
  owner_occ_property_cost: {
    shortFormTitle: 'Owner occupier property costs',
    tooltip: 'Body corporate fees, strata fees and land tax on owner occupied principal place of residence.',
  },
} as const;

export const AppValues = {
  COMPANY_NAME: import.meta.env.VITE_APP_CUSTOM_THEME_AGGREGATOR ?? 'Bridgit',
  COMPANY_NAME_SHORT: import.meta.env.VITE_APP_CUSTOM_THEME_AGGREGATOR_SHORT_NAME ?? 'Bridgit',
  CONTACT: import.meta.env.VITE_APP_CUSTOM_THEME_CONTACT ?? '1300 141 161',
  EMAIL: import.meta.env.VITE_APP_CUSTOM_THEME_EMAIL ?? 'hello@bridgit.com.au',
  DISCLAIMER:
    import.meta.env.VITE_APP_CUSTOM_THEME_DISCLAIMER ??
    'Eligibility and approval is subject to standard credit assessment and not all amounts, term lengths or rates will available to all applications. Fees, terms and conditions apply.',
  PRIVACY_POLICY_URL:
    import.meta.env.VITE_APP_CUSTOM_THEME_PRIVACY_POLICY_URL ?? 'https://www.bridgit.com.au/privacy-policy',
  TERMS_AND_CONDITIONS_URL:
    import.meta.env.VITE_APP_CUSTOM_THEME_TERMS_AND_CONDITIONS_URL ?? 'https://www.bridgit.com.au/terms-conditions',
};
